import React from "react"
import LangageSwitcher from "../components/layout/multiLanguage/LangageSwitcher"
import styled from "styled-components"
import GlobalStyle from "../components/layout/globalStyle"
import Provider from "../components/provider/provider"
import PageContainer from "../components/layout/pageContainer"
import Background from "../components/home/background"
import { fontSize } from "../components/provider/theme"
import Menu from "../components/layout/menu"
import { breakpoint } from "styled-components-breakpoint"
import SEO from "../components/layout/seo"

const Title = styled.h1`
  text-align: center;
  font-size: ${fontSize(`x_large`)};
  font-weight: normal;
  line-height: 1;

  ${breakpoint(`desktop`)`
    font-size: ${fontSize(`xx_large`)};
  `}
`

const Center = styled.div`
  width: 100%;
  max-width: 900px;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateX(-50%) translateY(-50%);
  padding: 50px;
  border-radius: 40%;
`

const IndexPage = props => {
  return (
    <Provider {...props}>
      <SEO title="Accueil" />
      <GlobalStyle />
      <Background>
        <PageContainer>
          <LangageSwitcher />
          <Center>
            <Title>Marjolaine Robert de Saint Vincent</Title>
            <Menu homepage />
          </Center>
        </PageContainer>
      </Background>
    </Provider>
  )
}

export default IndexPage
