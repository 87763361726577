import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { color } from "../provider/theme"

const Container = styled.div`
  background-color: ${color(`black`)};
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`

const BackgroundContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
`

const Content = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-image: radial-gradient(rgba(255, 255, 255, 0.5), transparent);
`

const ImageContainer = styled.div`
  opacity: ${props => (props.selected ? 1 : 0)};
  z-index: ${props => (props.selected ? 1 : 0)};
  transition: 2s opacity;
`

const Img = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 200px;
  object-fit: cover;
`

export default function Background({ children, timer = 10 }) {
  const {
    prismicAccueil: {
      data: { fond_d_ecran },
    },
  } = useStaticQuery(query)
  const [selectedImage, setSelectedImage] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedImage(index => (index + 1) % fond_d_ecran.length)
    }, timer * 1000)

    return () => clearInterval(interval)
  }, [fond_d_ecran.length, timer])

  return (
    <Container>
      <BackgroundContainer>
        {fond_d_ecran.map(({ image }, i) => (
          <ImageContainer key={i} selected={i === selectedImage}>
            <Img src={image.url} alt={image.alt} />
          </ImageContainer>
        ))}
      </BackgroundContainer>
      <Content>{children}</Content>
    </Container>
  )
}

const query = graphql`
  query {
    prismicAccueil {
      data {
        fond_d_ecran {
          image {
            url
            alt
          }
        }
      }
    }
  }
`
